import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

import "../index.scss"

import ImgLogo from "../../images/logo-tanecni-zlonin.svg"

import "../normalize.css"

import Bloglist from "../../components/bloglist/bloglist"
import Footer from "../../components/footer/footer"

const Article = () => {
	return (

		<Layout>
			<Seo title="Taneční kurzy pro děti ve Zloníně" />

			<div className="container container--article mb3">

				<Link to="/" data-sal="fade" data-sal-duration="1000">
					<img src={ImgLogo} alt="Taneční Zlonín logo" className="tz-miniheader__logo" />
				</Link>

				<h1 className="tz-miniheader__h1" data-sal="fade" data-sal-duration="1000">
					Taneční kurzy pro&nbsp;děti začínají již od&nbsp;první středy v&nbsp;září&nbsp;2024
				</h1>

				<div className="mb1">
					<Link to="/tanecni-pripravka-pro-deti" data-sal="fade" data-sal-duration="1000" className="tz-button tz-button--small tz-button--inverse">
						Taneční přípravka pro děti 5-6&nbsp;let
					</Link>
				</div>

				<div>
					<Link to="/tanecni-kurz-pro-deti" data-sal="fade" data-sal-duration="1000" className="tz-button tz-button--small tz-button--inverse">
						Taneční kurz pro děti 7-12&nbsp;let
					</Link>
				</div>

			</div>

			<Bloglist></Bloglist>
			<Footer></Footer>

		</Layout>

	)
}

export default Article